/* --------------------------------------------------
  Section
-------------------------------------------------- */


.site-section-banner {

  position: relative;

  .video-wrap {
    display: flex;
    height: 100vh;
    width: 100%;
    flex-wrap: wrap;
    max-height: 100vh;
    overflow: hidden;

    video {
      width: 100%;
      position: relative;
      object-fit: cover;
      height: 100%;
    }
  }

  .site-banner-content {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .site-content {
      width: 100%;
      display: flex;
      align-items: center;

      @media (max-width: 767px) {
        display: block;
      }

      .site-content-left {
        color: $white;
        position: relative;
        width: 69%;
        margin-right: 1%;

        @media (max-width: 767px) {
          width: 100%;
          margin-right: 0;
          margin-bottom: 50px;
        }

        h1 {
          line-height: 1.2;
          font-weight: bold;

          @media (max-width: 1024px) {
            line-height: 74px;
          }

          @media (max-width: 767px) {
            line-height: 44px;
            font-size: 28px;
          }

          @media (max-width: 374px) {
            line-height: 24px;
            font-size: 22px;
            margin-bottom: 15px;
          }
        }

        h4 {
          @media (max-width: 374px) {
            font-size: 18px;
          }
        }

        h3 {
          letter-spacing: 3px;
          line-height: 48px;
          font-weight: bold;


        }

      }

      .site-content-right {
        position: relative;
        width: 30%;
        background: $sit-bg-red;
        background: linear-gradient(90deg, rgba(135, 14, 5, 1) 0%, rgba(204, 30, 6, 1) 50%, rgba(135, 14, 5, 1) 100%);
        margin: 0 auto;

        @media (max-width: 767px) {
          width: 100%;
        }

        &::before {
          width: 8px;
          position: absolute;
          height: 100%;
          background-color: $white;
          content: "";
          top: 50%;
          left: -30px;
          transform: translateY(-50%);

          @media (max-width: 767px) {
            display: none;
          }
        }

        .btn-banner {
          width: 100%;
          display: block;
          padding: 15px;
          font-size: 72px;
          font-size: 4.3vw;
          font-weight: bold;
          line-height: 3.65vw;
          color: $white;
          text-align: center;
          text-decoration: none;
          cursor: pointer;

          // @media (min-width: 320px) and (max-width: 1024px) {
          //   font-size: 4.3vw;
          // }

          &:hover {
            transform: scale(1.1);
          }

          span {
            font-size: 26px;
            display: block;
            line-height: normal;
            padding: 15px 0 0;
            ;

            @media (min-width: 426px) and (max-width: 1024px) {
              font-size: 18px;
            }

            @media (max-width: 767px) {
              font-size: 15px;
              display: inline-block;
              padding-top: 0;
            }

            @media (min-width: 320px) and (max-width: 425px) {
              font-size: 15px;
            }




          }
        }
      }
    }
  }

}

.augmentes-wrap {
  &.mobile-show {
    display: none;

    @media(max-width: 767px) {
      display: block;
      background-color: $black;
    }

    @media (max-width: 374px) {
      padding: 10px 0;
    }
  }

  &.desktop-show {
    display: block;

    @media(max-width: 767px) {
      display: none;
    }
  }
}

.site-augmentes {
  text-align: center;
  background-color: rgba($black, 0.5);
  padding: 20px;
  position: absolute;
  bottom: 0;
  width: 100%;
  list-style-type: none;
  margin-bottom: 0;

  @media (max-width: 767px) {
    background-color: $black;
  }

  h6 {
    color: $agr-color;
    font-weight: bold;
    margin-bottom: 20px;

    @media (max-width: 767px) {
      padding-top: 5px;
      padding-bottom: 5px;
      margin-bottom: 0;
    }
  }

  li {
    display: inline-block;
    border-right: 2px solid $white;
    line-height: normal;

    a {
      color: $white;
      position: relative;
      padding: 0 15px;
      font-weight: lighter;

      &:hover {
        color: $red;
      }
    }

    &:last-child {
      border: none;
    }
  }
}

// Section Cards
.section-card {
  background: $section-card-bg;
  background: linear-gradient(-90deg, rgba(236, 237, 234, 1) 0%, rgba(248, 248, 246, 1) 50%, rgba(220, 219, 216, 1) 100%);

  .section-card-wrapper {
    padding-top: 70px;
    text-align: center;
    width: 100%;
    display: flex;
    margin-bottom: 40px;
    flex-wrap: wrap;
    justify-content: space-between;

    .section-card {
      background-image: url('../../__img/newlayout/Rectangle\ 1.png');
      background-repeat: no-repeat;
      padding: 15px 3.6vw 3.6vw;
      background-size: cover;
      filter: drop-shadow(4.702px 6.472px 1px rgba(60, 60, 59, 0.28));
      width: calc(50% - 5.1vw /2);
      // margin-right: 5vw;

      @media (min-width: 768px) and (max-width: 991px) {
        padding-left: 15px;
        padding-right: 15px;
        width: calc(50% - 3vw /2);
        margin-right: 3vw;
      }

      @media (max-width: 767px) {
        width: 100%;
        margin-right: 0;
        margin-bottom: 30px;
      }

      .bussiness-icon {
        margin-bottom: 28px;
        height: 140px;
        width: 120px;
        margin: auto;
      }

      .section-body {
        .card-title {
          position: relative;
          margin-bottom: 30px;
          letter-spacing: -1px;

          &::after {
            position: absolute;
            left: 50%;
            width: 203px;
            margin-top: 15px;
            margin-bottom: 15px;
            border-bottom: 9px solid $black;
            transform: translateX(-50%);
            content: " ";
          }

          h3 {
            font-weight: bold;
            color: $agr-color;
            font-size: 2vw;

            @media (max-width: 1920px) {
              font-size: 2.4vw;
            }

            @media (max-width: 767px) {
              font-size: 1.7rem;
            }
          }

        }

        .card-body {
          padding: 15px;
          display: inline-block;

          @media (min-width: 768px) and (max-width: 991px) {
            padding: 0;
          }

          p {
            text-align: left;
          }
        }
      }

      // Second Image

      &:nth-child(2) {
        background-image: url("../../__img/newlayout/Rectangle\ 2.png");
        margin: 0;
        background-position: right;
      }
    }
  }

  .site-section-banner-content {
    text-align: center;
    padding-bottom: 40px;

    .site-content-card {

      .iwt-btn-wrapper {
        @media (max-width: 767px) {
          padding: 0;
        }
      }

      .site-banner-contact {
        margin-bottom: 35px;

        h6 {
          font-weight: bold;
          color: $black;
        }
      }

      .btn-gr {
        font-size: 37px;
        padding: 20px 140px;
        position: relative;

        &::before {
          background-image: url('../assets/img/BOUTON_icon2.webp');
          content: " ";
          width: 121px;
          height: 121px;
          display: inline-block;
          position: absolute;
          background-repeat: no-repeat;
          left: -40px;
          top: 0;
          bottom: 0;
          margin: auto;
        }
      }

    }
  }
}

.site-expertise {
  background: $sit-bg-red;
  background: linear-gradient(90deg, rgba(135, 14, 5, 1) 0%, rgba(204, 30, 6, 1) 50%, rgba(135, 14, 5, 1) 100%);
  color: $white;

  @media (max-width: 991px) {
    margin-bottom: 20px;
  }
}

.site-expertise-left {
  padding: 20px 3vw 20px 9vw;

  @media (max-width: 767px) {
    padding: 20px 9vw 18px 9vw;

  }

  @media (max-width: 374px) {
    padding: 20px;
  }

  .tour-title {
    margin-bottom: 40px;

    @media (max-width: 991px) {
      text-align: center;
      margin-bottom: 15px;
    }

    @media (max-width: 374px) {
      margin-bottom: 25px;
    }

    h3 {
      font-weight: bold;

      @media (max-width: 767px) {
        line-height: 35px;
      }
    }
  }

  .tour-content {

    @media (max-width: 991px) {
      text-align: center;
    }

    a {



      h4 {
        position: relative;
        display: inline-block;
        color: $white;

        &::after {
          position: absolute;
          top: 0;
          bottom: 0;
          margin: auto;
          right: -3.8vw;
          display: inline-block;
          background-image: url('../assets/img/BOUTON_icon2.webp');
          width: 2vw;
          height: 2vw;
          background-size: 2vw;
          background-repeat: no-repeat;
          content: " ";

          @media (max-width: 1920px) {
            width: 2.9vw;
            height: 2.9vw;
            background-size: 2.9vw;
          }

          @media (max-width: 767px) {
            right: -35px;
          }

          @media (max-width: 767px) {
            width: 25px;
            height: 25px;
            background-size: 100%;
          }
        }
      }

      &:hover {
        h4 {
          &::after {
            transform: scale(1.2);
          }
        }
      }
    }
  }
}

.site-expertise-banner {
  height: 100%;

  @media (max-width: 991px) {
    padding-bottom: 15px;
  }

  img {
    height: 100%;
    object-fit: cover;

    @media (max-width: 767px) {
      height: 400px;
    }
  }

}

// Entrepreneurs-Quotes-Section

.section-entrepreneurs {
  background-color: $black;
  color: $white;
  text-align: left;

  .entrepreneurs-slider {
    // padding: 100px 0;
    // margin-bottom: 0;
    margin-bottom: 0;
    padding-top: 80px;
    padding-bottom: 130px;

    @media (max-width: 767px) {
      padding: 55px 0;
    }

    .slick-prev,
    .slick-next {
      display: none;

      &::before {
        content: " ";
      }
    }

    .slick-arrow {
      left: 0;
      right: 0;
    }

    .site-entrepreneurs {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      .site-entrepreneurs-left {
        width: calc(50% - 5vw);
        float: left;
        margin-right: 5vw;

        img {
          left: 30px;
          position: relative;

          @media (max-width: 991px) {
            position: unset;
            margin: auto;
          }
        }

        @media(max-width: 1920px) {
          width: calc(44% - 5vw);
        }

        @media (max-width: 991px) {
          width: 100%;
          margin-bottom: 30px;
        }
      }

      .site-entrepreneurs-right {
        width: calc(54% - 5vw);
        float: right;

        @media(max-width: 1920px) {
          width: calc(50% - 5vw);
          margin-left: 5vw;

        }

        @media (max-width: 991px) {
          width: 100%;
          margin-left: 0;
        }

        .entrepreneurs-quotes-title {
          margin-bottom: 30px;
          padding-left: 20px;

          @media (max-width: 991px) {
            margin-bottom: 35px;
            text-align: center;
          }

          h6 {
            margin-bottom: 20px;
          }

        }

        .entrepreneurs-quotes-body {
          position: relative;
          max-width: 29vw;
          margin-bottom: 40px;

          @media (max-width: 991px) {
            margin-bottom: 40px;
            text-align: center;
            max-width: 100%;
          }

          p {
            position: relative;
            padding-left: 20px;

            @media (min-width: 768px) and (max-width: 991px) {
              padding: 0 100px;
            }

            @media (max-width: 767px) {
              padding: 0 55px;
            }

            @media (max-width: 374px) {
              padding: 0px 40px;
            }

            &::before {
              background-image: url("../assets/img/Quote-icon.webp");
              background-repeat: no-repeat;
              position: absolute;
              content: " ";
              height: 55px;
              width: 70px;
              top: -30px;
              left: -80px;
              background-size: 100%;

              @media (min-width: 992px) and (max-width: 1024px) {
                height: 60px;
                width: 60px;
                top: -25px;
                left: -72px;
              }

              @media (min-width: 768px) and (max-width: 991px) {
                height: 80px;
                width: 80px;
                top: -30px;
                left: 10px;
              }

              @media (max-width: 767px) {
                left: 0;
                height: 44px;
                width: 44px;
                top: -15px;
                background-size: 100% auto;
              }

              @media (max-width: 374px) {
                height: 25px;
                width: 25px;
              }
            }

            &::after {
              background-image: url("../assets/img/Quote-icon.webp");
              background-repeat: no-repeat;
              position: absolute;
              content: " ";
              height: 55px;
              width: 70px;
              top: auto;
              left: auto;
              bottom: -2.8vw;
              margin-left: 20px;
              background-size: 100%;
              transform: rotate(180deg);

              @media (min-width: 992px) and (max-width: 1024px) {
                height: 60px;
                width: 60px;
              }

              @media (min-width: 768px) and (max-width: 991px) {
                height: 80px;
                width: 80px;
                bottom: -40px;
              }

              @media (max-width: 767px) {
                margin-left: 0;
                right: 45px;
                height: 44px;
                width: 44px;
                background-size: 100% auto;
              }

              @media (max-width: 374px) {
                height: 25px;
                width: 25px;
              }
            }
          }

        }
      }

    }
  }
}

.slick-dots {
  bottom: 60px;
  width: 100%;
  top: auto;
  left: 50%;
  transform: translateX(-50%);

  @media (max-width: 767px) {
    width: 100%;
  }

  .slick-active {
    border: 3px solid $red;

  }

  li {
    border-radius: 50%;
    border: 3px solid $white;
    margin: 0 14px;

    &:last-child {
      button {
        opacity: 0;
      }
    }

    &.slick-active {
      border: 3px solid $red;
    }

    @media (max-width: 374px) {
      height: 16px;
      width: 16px;
      margin: 0px 14px;

    }

    &:nth-child(2n+0) {
      top: 25px;
      transform: rotate(-57deg);
    }

    button {

      &::after {
        background-image: url("../assets/img/Ellipse-lign.png");
        background-repeat: no-repeat;
        content: " ";
        height: 30px;
        width: 35px;
        position: absolute;
        transform: rotate(53deg);

        &:last-child {
          display: none;
        }

        @media (max-width: 374px) {
          height: 16px;
          width: 30px;
          left: 15px;
        }
      }

      &::before {

        content: " ";

        @media (max-width: 374px) {
          top: -5px;
          left: -3px;
          width: 16px;
          height: 16px;

        }
      }

      @media (max-width: 374px) {
        width: 16px;
        height: 16px;
      }

    }

    @media (max-width: 374px) {
      margin: 0 15px;

    }

  }

  .slick-active {
    background-color: $text-title;
    border-radius: 50%;
    border: 0;
  }
}