/* --------------------------------------------------
	0.1 Settigns
-------------------------------------------------- */



/* ---- Colors ---- */
$white: #FFF;
$black: #000;
$gray: #808080;
$header-color:#CBCAD0;
$dark-gry: #2D2D2B;
$mid-gry: #3B3938;
$red: #B5121B;
$agr-color:#B21817;
$text-title:#B5121B;
$sit-bg-red: #870E05;
$section-card-bg: #ECEDEA;
$icon-bg: #EBECEC;
$icon-color: #343434;
$separator-color: #2E2D2C;
$title-divider: #010101;
$footer-bg: #2D2D33;
$button-border: #EDEDED;
$border-color:#977979;
$sub-hover:#f6f6f6;
$hover-text-color:#636363;
$gothic: "Century Gothic";
$gothic-url: url('/src/fonts/century-gothic-font/GOTHIC.TTF');

.btn-primary {
  color: #fff !important;
}
