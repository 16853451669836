/* --------------------------------------------------
  Footer Style
-------------------------------------------------- */
.footer {
  position: relative;
  display: inline-block;
  width: 100%;
  padding: 50px 0 90px;
  background-color: $black !important;
  font-family: $gothic;

  .container {
    max-width: 1600px !important;
    width: inherit !important;

    @media (max-width: 1920px) {
      max-width: 1300px !important;
      width: inherit !important;
    }

    @media(max-width: 992px) {
      max-width: 90vw !important;
      width: inherit !important;
    }

  }

  ul {
    li {
      list-style-type: none;
    }
  }

  .separator {

    &:first-child {
      padding-right: 0;

      .footer-nav {
        &::before {
          display: none;
        }
      }

      &::before {
        display: none;
      }
    }

    @media (max-width: 767px) {
      padding: 0;

      &:last-child {
        .footer-nav {
          &::after {
            display: none;
          }
        }
      }
    }
  }

  /* --------------------------------------------------
 Typography
-------------------------------------------------- */
  // h1,
  // .h1 {
  //   font-size: 28px;
  // }

  // h2,
  // .h2 {
  //   font-size: 28px;
  // }

  // h3,
  // .h3 {
  //   font-size: 24px;
  // }

  // h4,
  // .h4 {
  //   font-size: 22px;
  // }

  // h5,
  // .h5 {
  //   font-size: 18px;
  // }

  // h6,
  // .h6 {
  //   font-size: 16px;
  // }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    padding: 0;
    font-weight: bold;
    font-family: $gothic;
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    p {
      font-size: 18px;
    }

    h4 {
      font-size: 28px;
    }
  }

  @media(min-width: 1025px) {

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin: 0;
      padding: 0;
      font-weight: bold;
      font-family: $gothic;
    }

    h1,
    .h1 {
      /*font-size: 83px;*/
      font-size: 3.3vw;

      @media (max-width: 1920px) {
        font-size: 4.3vw;
      }
    }

    h2,
    .h2 {
      /*font-size: 88px;*/
      font-size: 3.6vw;

      @media (max-width: 1920px) {
        font-size: 4.6vw;
      }
    }

    h3,
    .h3 {
      /*font-size: 67px;*/
      font-size: 2.5vw;

      @media (max-width: 1920px) {
        font-size: 3.5vw;
      }

    }

    h4,
    .h4 {
      /*font-size: 42px;*/
      font-size: 1.2vw;

      @media (max-width: 1920px) {
        font-size: 2.2vw;
      }
    }

    h5,
    .h5 {
      /*font-size: 37px;*/
      font-size: 1vw;

      @media (max-width: 1920px) {
        font-size: 1.8vw;
      }
    }

    h6,
    .h6 {
      /*font-size: 29px;*/
      color: $white;
      font-size: 1vw;
      line-height: 1.72;

      // @media(max-width: 767px) {
      //   font-size: 16px;
      // }
      @media (max-width: 1920px) {
        font-size: 1.5vw;
      }
    }

    p {
      /*font-size: 25px;*/
      font-size: 1vw;
      line-height: 1.45;

      @media (max-width: 1920px) {
        font-size: 1.3vw;
      }

      &.large {
        /*font-size: 33px;*/
        font-size: 1.7vw;
      }

      &.small {
        /*font-size: 23px;*/
        font-size: 1.1vw;
      }
    }

    .large {
      p {
        @extend .large;
      }
    }

  }
}

// Links
.footer-links {
  h6 {
    position: relative;
    z-index: 1;
    display: inline-block;
    margin-bottom: 14px;
    color: $white;
    font-weight: 600;

    &::before {
      position: absolute;
      bottom: 0;
      left: -13px;
      z-index: -1;
      height: 22px;
      width: calc(100% + 26px);
      background-color: $footer-bg;
      content: '';

      @media (max-width: 767px) {
        bottom: -5px;
        height: 18px;
      }
    }
  }

  .footer-nav {
    position: relative;
    margin-bottom: 70px;
    padding-right: 10px;
    padding-left: 10px;
    list-style: none;

    &::before {
      position: absolute;
      top: 0;
      left: -21px;
      height: 280px;
      border-left: 1px solid $separator-color;
      content: '';

      @media (min-width:768px) and (max-width: 991px) {
        height: 100%;
      }
    }

    @media (max-width:767px) {
      &::after {
        position: absolute;
        bottom: -32px;
        left: 0;
        width: 100%;
        border-top: 1px solid #2E2D2C;
        content: '';

      }

      &::before {
        border: none;
      }
    }

    &:last-child {
      padding-right: 0;
    }

    li {

      font-size: 1.3vw;

      @media (max-width: 991px) {
        font-size: 14px;
      }

      a {
        color: $white;
        text-decoration: none;

        &:hover {
          margin-left: 2px;
          color: $red;
        }
      }
    }
  }
}

// Subscribe
.footer-email-wrapper {

  h5,
  .text-white {
    margin-bottom: 10px;
    font-weight: bold;

    a {
      text-decoration: none;

      &:hover {
        color: $red;
      }
    }

    @media (max-width: 767px) {
      text-align: center;
    }
  }
}

.mail-input {
  position: relative;
  display: flex;
  width: 100%;



  .input-field {
    height: 42px;
    width: 100%;
    padding: 10px 76px 10px 14px;
    border: none;
    background: $white;
    color: $separator-color;
    outline: none;
    box-shadow: none;
  }

  input::placeholder {
    opacity: 67%;
  }

  button {
    position: absolute;
    right: 0;
    height: 100%;
    width: 62px;
    padding: 0;
    border: none;
    background-color: $red;
    cursor: pointer;
    outline: none;

    &::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      height: 30px;
      width: 40px;
      margin: auto;
      background-image: url('../assets/img/mail-icon.png');
      content: '';
    }

  }

  .icon {
    padding: 10px;
    background: $red;
    color: $white;
    text-align: center;
  }
}

.social-media-icons {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);

  @media (max-width: 767px) {
    top: 43%;
  }

  .icons-list {
    padding: 0;

    li {
      margin-bottom: 10px;

      a {
        display: block;
        padding: 11px 18px;
        background: $icon-bg;
        text-decoration: none;

        @media(max-width: 767px) {
          padding: 11px 15px;
        }

        &:hover {
          background: $red;

          i {
            color: $white;
            transform: scale(1.2);
            transition-duration: .4s;
            transition-timing-function: ease;
          }
        }

        i {
          display: inherit;
          color: $icon-color;
          font-size: 42px;

          @media(max-width: 767px) {
            font-size: 25px;
          }
        }
      }
    }
  }
}

.footer-copyright {
  p {
    color: $white;

    @media (max-width: 1024px) and (min-width: 768px) {
      font-size: 1.3vw;
    }

    @media (min-width: 991px) {
      font-size: 1.3vw;
    }

    @media (max-width: 767px) {
      text-align: center;
    }
  }
}

.copy-right {
  // .footer-copyright {
  //   @media (max-width: 767px) {
  //     p {
  //       font-size: 1.3vw;
  //     }
  //   }
  // }

  .about-us {

    .about-list {
      justify-content: center;

      li {
        position: relative;
        margin-bottom: 15px;
        padding-right: 40px;

        @media (max-width: 425px) {
          padding-right: 30px;
        }

        a {
          color: $white;

          &:hover {
            color: $red;
          }
        }

        &::after {
          position: absolute;
          top: 0;
          right: 20px;
          bottom: 0;
          height: 25px;
          width: 1px;
          margin: auto;
          background-color: $white;
          content: '';

          @media (max-width: 425px) {
            right: 15px;
          }
        }

        &:last-child {
          padding-right: 0;

          &::after {
            display: none;

          }
        }

        a {
          display: inline-block;
          color: $white;

          p {
            margin-bottom: 0;
          }
        }

      }
    }
  }
}