$gothic: 'century_gothicregular';
$gothic-bold: 'century_gothicbold';

/* ---- Font Family ---- */
@font-face {
  font-family: 'century_gothicregular';
  src: url('../assets/fonts/century-gothic-font/gothic.woff2') format('woff2'),
    url('../assets/fonts/century-gothic-font/gothic.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'century_gothicbold';
  src: url('../assets/fonts/century-gothic-font/gothicb.woff2') format('woff2'),
    url('../assets/fonts/century-gothic-font/gothicb.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}