/* --------------------------------------------------
   Components
-------------------------------------------------- */
.image-text {
  position: relative;

  .side-strip {
    position: absolute;
    right: 0;
    height: 100%;
    width: 20px;
    margin: auto;
    @extend .bg-gold-gradient;
  }

  .iwt-wrapper {
    position: relative;

    .iwt-inner-wrapper {
      padding: 100px 0;


      @media (max-width: 767px) {
        padding: 50px 0;
      }
    }

    .become-partner {
      display: inline-block;
      width: 100%;
      padding: 0;

      @media (max-width: 991px) {
        margin-bottom: 30px;
        padding: 0;
      }

      .btn-arrow {
        left: -25px;

        @media (max-width: 767px) {
          left: -18px;
        }
      }
    }

    .img-wrapper {
      width: 100%;

      img {
        height: auto;
        width: 100%;
      }
    }

    .text-row {
      padding: 5px;
      background-color: $dark-gry;
      text-align: center;

      &:nth-child(2n) {
        background: $mid-gry;
      }

      h4,
      p {
        margin: 0;
        color: $white;
      }
    }


    .arrow-appli {
      position: relative;

      .appli {
        padding: 5px 50px;
        background-color: $red;
        color: $white;
        text-align: center;

        h4 {
          margin: 0;
          font-weight: 900;
        }
      }
    }

    .iwt-txt-wrapper {
      margin-left: 3vw;

      @media (max-width: 991px) {
        margin-left: 0;
      }

      .iwt-title {
        position: relative;
        margin-bottom: 30px;
        color: $black;

        h3 {
          font-weight: 900;

          &::before {
            position: absolute;
            bottom: -10px;
            z-index: -1;
            height: 8px;
            width: 30%;
            background-color: $black;
            content: '';
          }
        }
      }
    }

    .iwt-subtitle {
      margin-bottom: 65px;
      color: $red;

      @media (min-width: 992px) and (max-width: 1024px) {
        margin-bottom: 20px;
      }

      @media (max-width: 374px) {
        margin-bottom: 40px;
      }

      .build-business-title {
        max-width: 415px;
      }

    }

    .package {
      padding: 0 10px;
      display: inline-block;
      padding-bottom: 0;
      color: #3b3938;
      border-radius: 6px;

      &.platinum {
        background-color: #e4e4e2;
      }

      &.gold {
        background-color: #c8b273;
      }

      &.silver {
        background-color: #d7d8d7;
      }
    }

    .slick-slider {
      display: inline-block;
      width: auto;

      .slick-slide {
        width: 150px !important;

        @media (min-width: 992px) and (max-width: 1230px) {
          width: 110px !important
        }

        @media (max-width: 767px) {
          width: 100px !important;
        }

        @media (max-width: 374px) {
          width: 90px !important;
        }
      }
    }

    .image-slider {
      max-width: 100%;
      width: calc(100% + 30px);
      margin-right: -15px;
      margin-left: -15px;
      overflow: hidden;
      display: flex;

      .slider-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      .package {
        padding: 0 10px;
        display: inline-block;
        padding-bottom: 0;
        color: #3b3938;
        border-radius: 6px;

        &.platinum {
          background-color: #e4e4e2;
        }

        &.gold {
          background-color: #c8b273;
        }

        &.silver {
          background-color: #d7d8d7;
        }
      }

      .slick-slider {
        display: inline-block;
        width: auto;

        .slick-slide {
          width: 150px !important;

          @media (min-width: 992px) and (max-width: 1230px) {
            width: 110px !important
          }

          @media (max-width: 767px) {
            width: 100px !important;
          }

          @media (max-width: 374px) {
            width: 90px !important;
          }
        }
      }

      .slider-main,
      .slider-main-1,
      .slider-main-2 {
        // max-width: 50%;

        .slider-item-wrap {
          max-width: 145px;
          padding: 15px;
          display: flex !important;
          justify-content: center;
          align-items: center;

          @media (min-width: 992px) and (max-width: 1230px) {
            max-width: 110px;
          }

          @media (min-width: 768px) and (max-width: 991px) {
            max-width: 100%;
          }

          @media (max-width: 767px) {
            max-width: 100%;
            padding: 5px;
          }

          a {
            display: inline-block;
            height: 100px;
            width: 100px;

            @media (min-width: 992px) and (max-width: 1230px) {
              height: 80px;
              width: 80px;
            }

            @media (max-width: 767px) {
              height: 90px;
              width: 90px;
            }

            @media (max-width: 400px) {
              height: 70px;
              width: 70px;
            }

            img {
              height: 100%;
              width: 100%;
              object-fit: cover;
            }

          }
        }

        img {
          border-radius: 50%;
        }
      }
    }

    &:nth-child(2n) {
      .side-strip {
        right: auto;
        left: 0;
        background: $gray;
      }

      .iwt-txt-wrapper {
        margin-right: 2vw;
        margin-left: 0;
      }
    }
  }
}

.wrapper {
  width:100%;
  padding-top: 20px;
  text-align:center;

  .partenaire-title{
    width:100%;
    margin-bottom: 30px;
    font-weight: bold;
  }
  .spacing{
    margin-top: 10px;
  }

  .iwt-subtitle { 
    margin-top: 55px;
    margin-bottom: 35px;
    color: $red;
    text-align: center;

    @media (min-width: 992px) and (max-width: 1024px) {
      margin-bottom: 20px;
    }

    @media (max-width: 374px) {
      margin-bottom: 40px;
    }

    .build-business-title {
      max-width: 415px;
    }

  }

  .package {
    
    display: inline-block;
    padding-bottom: 0;
    color: #3b3938;
    width: 100%;

    &.platinum {
      background-color: #e4e4e2;
    }

    &.gold {
      background-color: #c8b273;
    }

    &.silver {
      background-color: #d7d8d7;
    }
  }

  .carousel{
    width:90%;
    margin:0px auto;
  }

  .carousel1{
    width:90%;
    margin:0px auto;
  }
  
  .carousel2{
    width:90%;
    margin:0px auto;
  }

  .slick-slide{
    margin:10px;
  
    img{
      width:100%;
      border: 2px solid #fff;
    }
  }
/*  .slick-prev{      
    &::before {
      font-size:20px;
      color:$black;
    }
  } 

  .slick-next{      
    &::before {
      font-size:20px;
      color:$black;
    }
  }*/
}

.economic-wrapper {
  display: inline-block;
  width: 100%;
  padding: 15px;
  background-color: $black;
  text-align: center;

  .container {
    text-align: center;

    .economic-title {
      display: inline-block;
      width: 100%;
      color: $white;
      text-align: center;
    }

    h4 {
      color: $white;
    }
  }

}

.card-image-with-text {
  overflow: hidden;

  .bg-img {

    width: 100%;


  }

  .main-cardwrap {
    position: relative;
    z-index: 1;
    // padding: 20px 0;

    @media (min-width: 768px) and (max-width: 991px) {
      padding: 0;
    }

    @media (min-width: 992px) and (max-width: 1024px) {
      padding: 0;
      padding-top: 50px;
    }

    .cart-head {
      position: relative;
      font-weight: bold;
      text-align: center;

      h2 {
        line-height: 76px;
        font-weight: bold;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        @extend .bg-gold-gradient;

        @media (max-width: 374px) {
          font-size: 28px;
        }

        @media (min-width: 992px) and (max-width: 1024px) {
          font-size: 50px;
        }

        @media (min-width: 1024px) {
          line-height: 1.2;
        }
      }

      .title-divider {
        &::after {
          bottom: -15px;
          height: 1vh;
          width: 20vw;
          background: $white;

          @media (max-width: 991px) {
            bottom: -5px;
            height: 5px;

          }

          @media (min-width: 1024px) {
            bottom: -20px;
            height: 5px;
          }
        }
      }
    }



    .credit-card-img {
      margin: 10px 0;
      text-align: center;
    }

    .client-benefits-section {
      max-width: 80%;
      margin: 25px auto 10px;

      @media (min-width: 768px) and (max-width: 1024px) {
        max-width: 95%;
      }

      @media (max-width: 991) {
        margin: 25px auto 15px;
      }

      @media (max-width: 767px) {
        max-width: 100%;
      }



      .benefit-wrap {

        .benefit-list {
          justify-content: center;

          @media (max-width: 767px) {
            display: flex;
            align-items: center;
            flex-direction: column;
          }


          li {
            position: relative;
            margin-bottom: 15px;
            padding-right: 40px;

            @media (max-width: 767px) {
              padding-right: 0;
            }

            a {
              color: $white;

              &:hover {
                color: $red;
              }
            }

            &::after {
              position: absolute;
              top: 0;
              right: 20px;
              bottom: 0;
              width: 1px;
              margin: auto;
              background-color: $white;
              content: '';

              @media (max-width: 767px) {
                position: absolute;
                top: 30px;
                bottom: 8px;
                left: 0;
                height: 1px;
                width: 100%;
                margin: auto;
                background-color: $white;
                content: '';
              }
            }

            &:last-child {
              padding-right: 0;

              &::after {
                display: none;

                @media (max-width: 767px) {
                  display: block;
                }
              }
            }

            a {
              display: inline-block;
              color: $white;

              p {
                margin-bottom: 0;
              }
            }

          }
        }

      }

    }

    .card-txt-link {
      text-align: center;

      p {
        color: $white;
        font-weight: 900;
        display: inline-block;

        @media (min-width: 768px) and (max-width: 991px) {
          margin: 0;
        }

        &:hover {
          a {
            color: $text-title;
            transform: scale(1.2);
            transition-duration: .4s;
            transition-timing-function: ease;
          }
        }
      }

      a {
        color: $white;
      }

    }
  }
}


// bottom section

.card-image-with-text {
  .image-section {
    overflow: hidden;

    .bg-img {
      height: 510px;
      object-fit: cover;

      @media (max-width: 1920px) {
        height: 480px;
      }

      @media (min-width: 768px) and (max-width: 991px) {
        height: 420px;
      }

      @media (max-width: 767px) {
        height: 350px;
      }
    }
  }

  .card-content {
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;

    &::after {
      position: absolute;
      top: 0;
      left: -244px;
      height: 510px;
      width: 100vw;
      background-image: url('../assets/img/graygredi.png');
      background-repeat: repeat;
      background-size: cover;
      content: '';


      @media (max-width: 1920px) {
        height: 480px;
      }

      @media (min-width: 768px) and (max-width: 991px) {
        left: -220px;
        height: 420px;
      }

      @media (max-width: 767px) {
        left: -205px;
        width: 965px;
      }

    }
  }
}