// Base File

body {
  /*font-size: 25px;*/
  font-size: 1vw;
  font-family: $gothic;

  @media (max-width: 1920px) {
    font-size: 1.04vw;
  }

  @media(max-width: 767px) {
    font-size: 14px !important;
  }
}

/* container change for extra large screen */
.container {
  max-width: 1600px !important;
  width: inherit !important;

  @media (max-width: 1920px) {
    max-width: 1300px !important;
    width: inherit !important;
  }

  @media(max-width: 992px) {
    max-width: 90vw !important;
    width: inherit !important;
  }

}

ul {
  li {
    list-style-type: none;
  }
}

a {
  color: $white;
  transition: all .2s ease;

  &:hover {
    text-decoration: none;
  }
}

img {
  height: auto;
  max-width: 100%;
}

.bg-black {
  background-color: $black;
  color: $white;
}

.bg-red {
  background-color: $red;
  color: $white;
}

.bg-white {
  background-color: $white;
}

.bg-red-gradient {
  background: rgb(132, 16, 3);
  background: -moz-linear-gradient(left, rgba(132, 16, 3, 1) 0%, rgba(203, 27, 6, 1) 49%, rgba(132, 16, 3, 1) 100%);
  background: -webkit-linear-gradient(left, rgba(132, 16, 3, 1) 0%, rgba(203, 27, 6, 1) 49%, rgba(132, 16, 3, 1) 100%);
  background: linear-gradient(to right, rgba(132, 16, 3, 1) 0%, rgba(203, 27, 6, 1) 49%, rgba(132, 16, 3, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#841003', endColorstr='#841003', GradientType=1);
}

.bg-gold-gradient {
  background: rgb(157, 115, 39);
  background: -moz-linear-gradient(top, rgba(157, 115, 39, 1) 0%, rgba(229, 180, 66, 1) 49%, rgba(157, 115, 39, 1) 100%);
  background: -webkit-linear-gradient(top, rgba(157, 115, 39, 1) 0%, rgba(229, 180, 66, 1) 49%, rgba(157, 115, 39, 1) 100%);
  background: linear-gradient(to bottom, rgba(157, 115, 39, 1) 0%, rgba(229, 180, 66, 1) 49%, rgba(157, 115, 39, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#9D7327', endColorstr='#9D7327', GradientType=0);
}

.title-divider {
  position: relative;
  margin-bottom: 50px;
  font-size: 2.88vw;

  &::after {
    position: absolute;
    right: 0;
    bottom: -20px;
    left: 0;
    height: 10px;
    width: 240px;
    margin: auto;
    background-color: $title-divider;
    content: '';
  }

  &.left-border {
    &::after {
      right: inherit;
    }
  }
}

.text-red {
  color: $red;
}